@import '@teamve/scania-icons-fonts/scania-icons-fonts.css';
@import './assets/scss/ng-select.scss';
@import './assets/mapAssets/map-styles.scss';

@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';

@import url('@scania/tegel/dist/tegel/tegel.css');

body {
  width: 100%;
  padding: 0;
  margin: 0;
}

// hiding mopinion form
#surveyContent {
  display: none;
}

* {
  box-sizing: border-box;
}

main {
  margin-top: 0 !important;
}

.no-padding {
  padding: 0;
}

/*PANEL TODO MAKE A COMPONENT*/
.panel {
  background: #fff;
  padding: 4rem;
  width: 100%;
  margin-bottom: 24px;
}

.tab-content {
  padding-top: 24px;
}

.page:not(.charging-management) {
  padding: 4rem;

  @media screen and (min-width: 769px) {
    padding: 6.5rem 29px !important;
  }

  h1,
  h2 {
    @media screen and (max-width: 768px) {
      margin: 3.5rem 0;
      font-size: 24px;
    }
  }
}

.hide-on-mobile {
  display: none !important;

  @media screen and (min-width: 769px) {
    display: inline-block !important;
  }
}

.icon-button {
  padding: 0;
  height: 20px;
  width: 20px;
  position: relative;

  & + .icon-button {
    margin-left: 2rem;
  }

  &:hover {
    color: var(--tds-grey-800);
  }

  @media screen and (max-width: 768px) {
    padding: 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.48);
    border-radius: 4px;
    color: var(--tds-grey-958);
    height: auto;
    width: auto;
  }

  &:hover {
    &:before {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--tds-grey-500);
    }
  }

  i {
    position: relative;
    height: 20px;
    width: 20px;
    display: block;

    &:before {
      position: absolute;
      right: 0;
    }

    &.f2x {
      font-size: 5rem;
    }
  }
}

.mb-6 {
  margin-bottom: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*forms*/
.form-divider {
  height: 1px;
  padding: 0;
  width: 100%;
  background-color: var(--tds-grey-300);
  margin: 24px 0;
}

.block {
  color: var(--tds-block-color);
  border-radius: 4px;
  padding: 16px;
  background-color: var(--tds-block-background);
}

.toast-container {
  .ngx-toastr {
    background: var(--tds-grey-958);
    opacity: 0.8;
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.15),
      0px -1px 1px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 14px;
    padding: 10px;
    line-height: 1.6em;

    &.toast-success {
      border-left: 6px solid var(--tds-positive);
    }

    &.toast-error {
      border-left: 6px solid var(--tds-negative);
    }

    &.toast-info {
      border-left: 6px solid var(--tds-information);
    }

    &.toast-warning {
      border-left: 6px solid var(--tds-warning);
    }
  }
}

// TEGEL WITH SDDS WORKOUND
// Reset default margins for header elements
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.margin-bot-8 {
  margin-bottom: 8px;
}

.margin-bot-16 {
  margin-bottom: 16px;
}

.margin-bot-20 {
  margin-bottom: 20px;
}

.margin-bot-24 {
  margin-bottom: 24px;
}

.margin-bot-32 {
  margin-bottom: 32px;
}

.padding-16 {
  padding: 16px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-32 {
  margin-top: 32px;
}

.max-width-700 {
  max-width: 700px;
}

.tds-detail-02 {
  font: var(--tds-detail-02);
  letter-spacing: var(--tds-detail-02-ls);
}

.skeleton-loader {
  border-radius: 8px;
  background-color: #e2e5e7;
  // The shine that's going to move across the skeleton:
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-size: 40px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

.tds-mode-dark .skeleton-loader {
  // skeleton loader styling for dark mode
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

#map {
  width: 100%;
  height: calc(100dvh - 62px);
  // 62 px is top bar height
}

.H_ui {
  z-index: 1 !important;
}

.toastr-on-the-map {
  margin: 24px !important;
}

.custom-zoom-controls {
  background: unset !important;
  display: grid;
  gap: 8px;
  box-shadow: none !important;

  .H_btn.H_el {
    background: rgba(226, 244, 222, 0.9) !important;
    -webkit-backdrop-filter: blur(44px);
    backdrop-filter: blur(44px) !important;
    padding: 7px;
    // padding: 10px var(--spacing-element-12, 12px);
    border-radius: 12px;
  }

  .H_btn.H_el::after {
    content: none !important;
  }
}

.H_copyright {
  display: none !important;
}

.H_ctl.H_el.H_scalebar {
  display: none;
}

.H_imprint {
  display: none !important;
}

// here maps bubble styling
.H_ib_close {
  display: none;
}

.H_ib {
  //
  // margin-top: -45px;
}

.H_ib_content {
  margin: 0 !important;
  // margin: 0 50%;
}

.H_ib_tail {
  display: none;
}

// NG SELECT ///

.ng-select {
  &.thin .ng-select-container {
    height: 48px;
  }
  .ng-select-container {
    border-radius: 0px;
    .ng-value-container {
      padding-left: 0;

      // input {
      //   // display: none;
      //   // height: 0;
      //   height: 100%;
      // }
      .ng-input {
        top: inherit !important;
      }
    }
  }
  &.ng-select-single {
    .ng-clear-wrapper {
      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Lager_13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 256 256' style='enable-background:new 0 0 256 256;' xml:space='preserve'%3E%3Cpolygon style='fill:%23041E42;' points='187.535,75.556 180.465,68.483 127.992,120.944 75.532,68.472 68.46,75.542 120.92,128.014 68.448,180.475 75.519,187.547 127.991,135.086 180.451,187.559 187.523,180.488 135.063,128.016 '/%3E%3C/svg%3E ");
        background-position: right center;
        background-repeat: no-repeat;
        outline: none;
        border-radius: 0px;
        border-style: none;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        background-size: 18px;
        display: inline-block;
      }
      .ng-clear {
        display: inline-block;
        font-size: 30px;
        line-height: unset;
        pointer-events: none;
        color: #53565a;
        display: none;
      }
    }
    .ng-dropdown-header {
      border: 0;
      padding: 0;
      input:focus {
        outline: none;
      }
    }
    .ng-select-container {
      border-radius: 1rem 1rem 0px 0px;
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      margin: 0px;
      border: none;
      outline: none;
      color: var(--tds-textfield-color);
      font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
      font-size: 3.5rem;
      line-height: 4rem;
      letter-spacing: -0.01em;
      padding: var(--tds-spacing-element-20) var(--tds-spacing-element-16);
      border-bottom: 1px solid var(--tds-grey-800);
      transition: border-bottom-color 200ms ease 0s;

      &:hover {
        border-bottom-color: var(--tds-textfield-border-bottom-hover);
      }
      &:focus,
      &:active {
        border-bottom-color: var(--tds-textfield-bar);
      }
    }
    &.ng-select-opened {
      .ng-arrow-wrapper {
        .ng-arrow {
          margin-top: 2px;
          transform: rotate(180deg);
          animation: rotationUp 0.2s linear;
        }
      }
    }
    .ng-arrow-wrapper {
      .ng-arrow {
        height: 7px;
        background-position: center center;
        background-repeat: no-repeat;
        outline: none;
        border-radius: 0px;
        border-style: none;
        width: 12px;
        vertical-align: middle;
        border-color: transparent;
        border-style: 0;
        border-width: 0;
        animation: rotationDown 0.2s linear;
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      height: auto;
      min-height: 56px;
      width: 100%;
      box-shadow: none;
      box-sizing: border-box;
      border-radius: 1rem 1rem 0px 0px;
      margin: 0px;
      border: none;
      outline: none;
      color: var(--tds-textfield-color);
      font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
      font-size: 3.5rem;
      line-height: 4rem;
      letter-spacing: -0.01em;
      padding: var(--tds-spacing-element-4) var(--tds-spacing-element-16);
      border-bottom: 1px solid var(--tds-grey-400);
      transition: border-bottom-color 200ms ease 0s;
      &.ng-has-value {
        border-bottom-color: var(--tds-grey-800);
      }
      &:hover {
        border-bottom-color: var(--tds-textfield-border-bottom-hover);
      }
      &:focus,
      &:active {
        border-width: 2px;
        border-bottom-color: var(--tds-textfield-bar);
      }
      .ng-value-container {
        padding: 5px 0;
        .ng-placeholder {
          top: 0;
          position: relative !important;
          padding: 0;
        }
        .ng-value {
          padding: 1rem 3rem;
          margin: 4px 0;
          font-size: 3rem;
          border: 2px solid var(--tds-grey-100);
          border-radius: 50px;
          box-shadow: none;
          white-space: nowrap;
          font-family: 'Scania Sans Semi Condensed';
          background: var(--tds-grey-100);
          color: #000;
          display: flex;
          flex-direction: row-reverse;
          &.ng-value-error {
            background-color: var(--tds-red-200);
            border: 2px solid var(--tds-red-200);
          }
          + .ng-value {
            margin-left: 5px;
          }
          .ng-value-icon {
            vertical-align: middle;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMi4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGFnZXJfMTMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTYgMjU2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiMwNDFFNDI7IiBwb2ludHM9IjE4Ny41MzUsNzUuNTU2IDE4MC40NjUsNjguNDgzIDEyNy45OTIsMTIwLjk0NCA3NS41MzIsNjguNDcyIDY4LjQ2LDc1LjU0MiAxMjAuOTIsMTI4LjAxNCAKCTY4LjQ0OCwxODAuNDc1IDc1LjUxOSwxODcuNTQ3IDEyNy45OTEsMTM1LjA4NiAxODAuNDUxLDE4Ny41NTkgMTg3LjUyMywxODAuNDg4IDEzNS4wNjMsMTI4LjAxNiAiLz4KPC9zdmc+Cg==');
            background-position: center center;
            background-size: 4rem;
            background-repeat: no-repeat;
            width: 10px;
            height: 17px;
            display: inline-flex;
            font-size: 0px;
            margin-left: 1rem;
            &.left {
              border: 0;
            }
          }
        }
      }
    }
    &.ng-select-focused {
      .ng-select-container {
        border-width: 2px;
        border-bottom-color: var(--tds-textfield-bar);
      }
    }
    .ng-clear-wrapper {
      display: none;
      &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Lager_13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 256 256' style='enable-background:new 0 0 256 256;' xml:space='preserve'%3E%3Cpolygon style='fill:%23041E42;' points='187.535,75.556 180.465,68.483 127.992,120.944 75.532,68.472 68.46,75.542 120.92,128.014 68.448,180.475 75.519,187.547 127.991,135.086 180.451,187.559 187.523,180.488 135.063,128.016 '/%3E%3C/svg%3E ");
        background-position: right center;
        background-repeat: no-repeat;
        outline: none;
        border-radius: 0px;
        border-style: none;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        background-size: 18px;
        display: inline-block;
      }
      .ng-clear {
        display: inline-block;
        font-size: 30px;
        line-height: unset;
        pointer-events: none;
        color: #53565a;
        display: none;
      }
    }
    .ng-arrow-wrapper {
      display: none;
      .ng-arrow {
        height: 7px;
        background-position: center center;
        background-repeat: no-repeat;
        outline: none;
        border-radius: 0px;
        border-style: none;
        width: 12px;
        vertical-align: middle;
        border-color: transparent;
        border-style: 0;
        border-width: 0;
        animation: rotationDown 0.2s linear;
      }
    }
    &.ng-select-opened {
      .ng-arrow-wrapper .ng-arrow {
        margin-top: 2px;
        transform: rotate(180deg);
        animation: rotationUp 0.2s linear;
      }
    }
  }
  &.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      box-shadow: none;
    }
  }
  &.ng-select-opened {
    .ng-select-container {
      z-index: unset !important;
      border-bottom-color: var(--tds-textfield-bar);
    }
  }
}

.ng-dropdown-panel {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px 0px;
  border-left-color: var(--tds-grey-100);
  border-right-color: var(--tds-grey-100);
  z-index: 1000 !important;
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;

  &.ng-select-bottom {
    margin-top: 0;
  }

  .ng-dropdown-panel-items {
    max-height: 346px !important;
    .ng-option {
      border-bottom: 1px solid var(--tds-grey-100);
      padding: 18px 16px;
      img.rounded {
        height: 40px;
      }
      &.ng-option-selected {
        background: #fff;
        display: flex;
        &:after {
          content: '';
          display: inline-block;
          margin-left: auto;
          width: 16px;
          height: 10px;
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='0 0 33.8799991607666 24.25' id='Layer_1_1624805094877' width='28' height='20'%3E%3Cg transform='translate(1, 1)'%3E%3Cdefs%3E%3Cstyle%3E.cls-1_1624805094877%7Bfill:%232c3e50;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Esp_checks-01%3C/title%3E%3Cpath transform='translate(-3.14 -7.74)' d='M14.26,30h0a1.5,1.5,0,0,1-1.06-.44L3.58,19.93A1.5,1.5,0,1,1,5.7,17.81l8.56,8.57,18.2-18.2a1.5,1.5,0,0,1,2.12,2.12L15.32,29.56A1.5,1.5,0,0,1,14.26,30Z' class='cls-1_1624805094877' vector-effect='non-scaling-stroke' style='fill: rgb(83, 86, 90);'/%3E%3C/g%3E%3C/svg%3E");
        }
        &.ng-option-marked {
          background: var(--tds-grey-100);
          .ng-option-label {
            font-weight: 400;
          }
        }
        .ng-option-label {
          font-weight: 400;
        }
      }
      &.ng-option-marked {
        background: var(--tds-grey-100);
      }
    }

    &::-webkit-scrollbar {
      width: 11px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: white; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--tds-grey-100); /* color of the scroll thumb */
      border-radius: 5px; /* roundness of the scroll thumb */
      border: 2px solid white; /* creates padding around scroll thumb */
    }
  }
  .ng-select-no-results {
    color: #8a6d3b;
    padding-left: 10px;
    padding: 8px 16px;
  }
}

.ng-select-search {
  border: none;
  background-color: #f5f5f5;
  padding: 7px;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
}

.ng-dropdown-header {
  .input-group {
    border: 7px solid #fff;
    display: flex;
    align-items: center;
    .input-group-text {
      padding: 3px;
      border: none;
      background: #fff;
      font-size: 20px;
    }
    .ng-select-search {
      padding-left: 0px;
      border: none;
      background-color: #fff;
      padding: 7px;
      flex: 1 1 auto;
      min-width: 0;
    }
  }
}

.ng-option-label .select2-match {
  text-decoration: underline;
}

@keyframes rotationUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotationDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@font-face {
	font-family: "scania-icons-fonts";
	src: url('fonts/scania-icons-fonts.eot?1679660837');
	src: url('fonts/scania-icons-fonts.eot?#iefix#1679660837') format('eot'),
		url('fonts/scania-icons-fonts.woff2?1679660837') format('woff2'),
		url('fonts/scania-icons-fonts.woff?1679660837') format('woff'),
		url('fonts/scania-icons-fonts.ttf?1679660837') format('truetype'),
		url('fonts/scania-icons-fonts.svg?1679660837#scania-icons-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="cui-"], [class*=" cui-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'scania-icons-fonts' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fas {
  font-weight: 900;
}
.fal {
  font-weight:300;
}

.cui-myscania-app-address-book:before {
  content: "\E001";
}
.cui-myscania-app-driver-evaluation:before {
  content: "\E002";
}
.cui-myscania-app-equipment:before {
  content: "\E003";
}
.cui-myscania-app-fleet-position:before {
  content: "\E004";
}
.cui-myscania-app-home:before {
  content: "\E005";
}
.cui-myscania-app-monitoring-report:before {
  content: "\E006";
}
.cui-myscania-app-service-planning:before {
  content: "\E007";
}
.cui-myscania-app-settings:before {
  content: "\E008";
}
.cui-myscania-app-tacho:before {
  content: "\E009";
}
.cui-myscania-app-vehicle-performance:before {
  content: "\E00A";
}
.cui-scania-24h_service:before {
  content: "\E00B";
}
.cui-scania-accident_repair:before {
  content: "\E00C";
}
.cui-scania-adblue-inactive:before {
  content: "\E00D";
}
.cui-scania-adblue:before {
  content: "\E00E";
}
.cui-scania-add:before {
  content: "\E00F";
}
.cui-scania-address-book:before {
  content: "\E010";
}
.cui-scania-adr_vehicle_service:before {
  content: "\E011";
}
.cui-scania-alternative_fuels:before {
  content: "\E012";
}
.cui-scania-angle-down:before {
  content: "\E013";
}
.cui-scania-applauncher:before {
  content: "\E014";
}
.cui-scania-arrow-down:before {
  content: "\E015";
}
.cui-scania-arrow-right:before {
  content: "\E016";
}
.cui-scania-arrow-up:before {
  content: "\E017";
}
.cui-scania-arrow:before {
  content: "\E018";
}
.cui-scania-availability:before {
  content: "\E019";
}
.cui-scania-battery-new-inactive:before {
  content: "\E01A";
}
.cui-scania-battery-new:before {
  content: "\E01B";
}
.cui-scania-battery:before {
  content: "\E01C";
}
.cui-scania-bell:before {
  content: "\E01D";
}
.cui-scania-bio-natural_gas:before {
  content: "\E01E";
}
.cui-scania-biodiesel:before {
  content: "\E01F";
}
.cui-scania-bioethanol:before {
  content: "\E020";
}
.cui-scania-boat:before {
  content: "\E021";
}
.cui-scania-book:before {
  content: "\E022";
}
.cui-scania-broken-link:before {
  content: "\E023";
}
.cui-scania-bus-sale:before {
  content: "\E024";
}
.cui-scania-bus:before {
  content: "\E025";
}
.cui-scania-bus_application:before {
  content: "\E026";
}
.cui-scania-bus_connected:before {
  content: "\E027";
}
.cui-scania-bus_service:before {
  content: "\E028";
}
.cui-scania-business_operator:before {
  content: "\E029";
}
.cui-scania-cab:before {
  content: "\E02A";
}
.cui-scania-charging:before {
  content: "\E02B";
}
.cui-scania-check-list:before {
  content: "\E02C";
}
.cui-scania-circular_arrows:before {
  content: "\E02D";
}
.cui-scania-clock-new-inactive:before {
  content: "\E02E";
}
.cui-scania-clock-new:before {
  content: "\E02F";
}
.cui-scania-cloud:before {
  content: "\E030";
}
.cui-scania-co2:before {
  content: "\E031";
}
.cui-scania-coffee-break:before {
  content: "\E032";
}
.cui-scania-cogwheel_star:before {
  content: "\E033";
}
.cui-scania-collapse:before {
  content: "\E034";
}
.cui-scania-comfort_passenger:before {
  content: "\E035";
}
.cui-scania-communication:before {
  content: "\E036";
}
.cui-scania-compass:before {
  content: "\E037";
}
.cui-scania-concrete_mixer:before {
  content: "\E038";
}
.cui-scania-configurator:before {
  content: "\E039";
}
.cui-scania-connected_engines:before {
  content: "\E03A";
}
.cui-scania-crane:before {
  content: "\E03B";
}
.cui-scania-cross:before {
  content: "\E03C";
}
.cui-scania-cross_circle:before {
  content: "\E03D";
}
.cui-scania-data:before {
  content: "\E03E";
}
.cui-scania-date:before {
  content: "\E03F";
}
.cui-scania-detect_location:before {
  content: "\E040";
}
.cui-scania-diamond:before {
  content: "\E041";
}
.cui-scania-download-new:before {
  content: "\E042";
}
.cui-scania-download:before {
  content: "\E043";
}
.cui-scania-driveability:before {
  content: "\E044";
}
.cui-scania-driver_bus:before {
  content: "\E045";
}
.cui-scania-driving_licence:before {
  content: "\E046";
}
.cui-scania-edit-pen:before {
  content: "\E047";
}
.cui-scania-efficiency:before {
  content: "\E048";
}
.cui-scania-email:before {
  content: "\E049";
}
.cui-scania-engine:before {
  content: "\E04A";
}
.cui-scania-engine_application:before {
  content: "\E04B";
}
.cui-scania-engine_sale:before {
  content: "\E04C";
}
.cui-scania-engines_service:before {
  content: "\E04D";
}
.cui-scania-error:before {
  content: "\E04E";
}
.cui-scania-event:before {
  content: "\E04F";
}
.cui-scania-exclamation-circle:before {
  content: "\E050";
}
.cui-scania-exclamation-triangle:before {
  content: "\E051";
}
.cui-scania-expand:before {
  content: "\E052";
}
.cui-scania-external_link:before {
  content: "\E053";
}
.cui-scania-eye-new:before {
  content: "\E054";
}
.cui-scania-facebook:before {
  content: "\E055";
}
.cui-scania-filter:before {
  content: "\E056";
}
.cui-scania-finance:before {
  content: "\E057";
}
.cui-scania-find_a_dealer:before {
  content: "\E058";
}
.cui-scania-flickr:before {
  content: "\E059";
}
.cui-scania-fms-addressbook:before {
  content: "\E05A";
}
.cui-scania-fms-calender:before {
  content: "\E05B";
}
.cui-scania-fms-check:before {
  content: "\E05C";
}
.cui-scania-fms-checklist:before {
  content: "\E05D";
}
.cui-scania-fms-checks:before {
  content: "\E05E";
}
.cui-scania-fms-cog:before {
  content: "\E05F";
}
.cui-scania-fms-compare:before {
  content: "\E060";
}
.cui-scania-fms-defect-reports:before {
  content: "\E061";
}
.cui-scania-fms-details:before {
  content: "\E062";
}
.cui-scania-fms-driver-data:before {
  content: "\E063";
}
.cui-scania-fms-driver-wheel:before {
  content: "\E064";
}
.cui-scania-fms-env-report:before {
  content: "\E065";
}
.cui-scania-fms-equipment:before {
  content: "\E066";
}
.cui-scania-fms-events:before {
  content: "\E067";
}
.cui-scania-fms-fuel-meter:before {
  content: "\E068";
}
.cui-scania-fms-fuel:before {
  content: "\E069";
}
.cui-scania-fms-fuelreport:before {
  content: "\E06A";
}
.cui-scania-fms-geozones:before {
  content: "\E06B";
}
.cui-scania-fms-instrumentation:before {
  content: "\E06C";
}
.cui-scania-fms-locations:before {
  content: "\E06D";
}
.cui-scania-fms-map:before {
  content: "\E06E";
}
.cui-scania-fms-monitoring-report:before {
  content: "\E06F";
}
.cui-scania-fms-overview:before {
  content: "\E070";
}
.cui-scania-fms-play:before {
  content: "\E071";
}
.cui-scania-fms-position:before {
  content: "\E072";
}
.cui-scania-fms-report:before {
  content: "\E073";
}
.cui-scania-fms-result:before {
  content: "\E074";
}
.cui-scania-fms-route:before {
  content: "\E075";
}
.cui-scania-fms-service-coverage:before {
  content: "\E076";
}
.cui-scania-fms-settings:before {
  content: "\E077";
}
.cui-scania-fms-tacho:before {
  content: "\E078";
}
.cui-scania-fms-todo:before {
  content: "\E079";
}
.cui-scania-fms-tracking:before {
  content: "\E07A";
}
.cui-scania-fms-vehicles:before {
  content: "\E07B";
}
.cui-scania-fms-workshop-history:before {
  content: "\E07C";
}
.cui-scania-fms-wrench:before {
  content: "\E07D";
}
.cui-scania-fms-zone-management:before {
  content: "\E07E";
}
.cui-scania-forbiden_enter:before {
  content: "\E07F";
}
.cui-scania-fuel-gauge-inactive:before {
  content: "\E080";
}
.cui-scania-fuel-gauge:before {
  content: "\E081";
}
.cui-scania-fuel_cell:before {
  content: "\E082";
}
.cui-scania-fuel_meter:before {
  content: "\E083";
}
.cui-scania-gear:before {
  content: "\E084";
}
.cui-scania-genset_sale:before {
  content: "\E085";
}
.cui-scania-gensets_service:before {
  content: "\E086";
}
.cui-scania-globe:before {
  content: "\E087";
}
.cui-scania-graph_check:before {
  content: "\E088";
}
.cui-scania-graph_search:before {
  content: "\E089";
}
.cui-scania-home:before {
  content: "\E08A";
}
.cui-scania-hourglass-new:before {
  content: "\E08B";
}
.cui-scania-hourglass:before {
  content: "\E08C";
}
.cui-scania-hvo:before {
  content: "\E08D";
}
.cui-scania-hybrid:before {
  content: "\E08E";
}
.cui-scania-hydraulics_service:before {
  content: "\E08F";
}
.cui-scania-info-circle:before {
  content: "\E090";
}
.cui-scania-information:before {
  content: "\E091";
}
.cui-scania-instagram:before {
  content: "\E092";
}
.cui-scania-instrumentation:before {
  content: "\E093";
}
.cui-scania-kebab-menu:before {
  content: "\E094";
}
.cui-scania-lightbulb:before {
  content: "\E095";
}
.cui-scania-linkedin:before {
  content: "\E096";
}
.cui-scania-listen_driver:before {
  content: "\E097";
}
.cui-scania-lock:before {
  content: "\E098";
}
.cui-scania-marine_engine_sale:before {
  content: "\E099";
}
.cui-scania-marine_engine_service:before {
  content: "\E09A";
}
.cui-scania-meatballs:before {
  content: "\E09B";
}
.cui-scania-mechanic_technician:before {
  content: "\E09C";
}
.cui-scania-menu-mobile:before {
  content: "\E09D";
}
.cui-scania-menu_white:before {
  content: "\E09E";
}
.cui-scania-mileage-inactive:before {
  content: "\E09F";
}
.cui-scania-mileage:before {
  content: "\E0A0";
}
.cui-scania-mining_sale:before {
  content: "\E0A1";
}
.cui-scania-mining_service:before {
  content: "\E0A2";
}
.cui-scania-minus:before {
  content: "\E0A3";
}
.cui-scania-news:before {
  content: "\E0A4";
}
.cui-scania-no-sound:before {
  content: "\E0A5";
}
.cui-scania-no_phone:before {
  content: "\E0A6";
}
.cui-scania-no_photo:before {
  content: "\E0A7";
}
.cui-scania-no_smoking:before {
  content: "\E0A8";
}
.cui-scania-no_wifi:before {
  content: "\E0A9";
}
.cui-scania-open_24h:before {
  content: "\E0AA";
}
.cui-scania-part_sale:before {
  content: "\E0AB";
}
.cui-scania-passenger:before {
  content: "\E0AC";
}
.cui-scania-payload:before {
  content: "\E0AD";
}
.cui-scania-pdf:before {
  content: "\E0AE";
}
.cui-scania-phone:before {
  content: "\E0AF";
}
.cui-scania-pin-flag:before {
  content: "\E0B0";
}
.cui-scania-pin-new-inactive:before {
  content: "\E0B1";
}
.cui-scania-pin-new:before {
  content: "\E0B2";
}
.cui-scania-plus:before {
  content: "\E0B3";
}
.cui-scania-power-plug:before {
  content: "\E0B4";
}
.cui-scania-powergen:before {
  content: "\E0B5";
}
.cui-scania-productivity-2:before {
  content: "\E0B6";
}
.cui-scania-productivity:before {
  content: "\E0B7";
}
.cui-scania-profile-new-inactive:before {
  content: "\E0B8";
}
.cui-scania-profile-new:before {
  content: "\E0B9";
}
.cui-scania-profile_icon:before {
  content: "\E0BA";
}
.cui-scania-range-support:before {
  content: "\E0BB";
}
.cui-scania-refresh:before {
  content: "\E0BC";
}
.cui-scania-repairs:before {
  content: "\E0BD";
}
.cui-scania-rest:before {
  content: "\E0BE";
}
.cui-scania-road:before {
  content: "\E0BF";
}
.cui-scania-roller_break:before {
  content: "\E0C0";
}
.cui-scania-safety:before {
  content: "\E0C1";
}
.cui-scania-save:before {
  content: "\E0C2";
}
.cui-scania-search-details:before {
  content: "\E0C3";
}
.cui-scania-search:before {
  content: "\E0C4";
}
.cui-scania-seatbelt:before {
  content: "\E0C5";
}
.cui-scania-settings:before {
  content: "\E0C6";
}
.cui-scania-share_page_tool:before {
  content: "\E0C7";
}
.cui-scania-shopping_cart:before {
  content: "\E0C8";
}
.cui-scania-sliders:before {
  content: "\E0C9";
}
.cui-scania-slideshare:before {
  content: "\E0CA";
}
.cui-scania-sort:before {
  content: "\E0CB";
}
.cui-scania-sound:before {
  content: "\E0CC";
}
.cui-scania-speedometer-inactive:before {
  content: "\E0CD";
}
.cui-scania-speedometer:before {
  content: "\E0CE";
}
.cui-scania-square_stack:before {
  content: "\E0CF";
}
.cui-scania-steering-wheel:before {
  content: "\E0D0";
}
.cui-scania-tachograph_analogue:before {
  content: "\E0D1";
}
.cui-scania-tail_lift:before {
  content: "\E0D2";
}
.cui-scania-tail_lift_service:before {
  content: "\E0D3";
}
.cui-scania-tarpaulin_service:before {
  content: "\E0D4";
}
.cui-scania-team:before {
  content: "\E0D5";
}
.cui-scania-tick:before {
  content: "\E0D6";
}
.cui-scania-timer-inactive:before {
  content: "\E0D7";
}
.cui-scania-timer:before {
  content: "\E0D8";
}
.cui-scania-toe:before {
  content: "\E0D9";
}
.cui-scania-toll_collect_recovery_service:before {
  content: "\E0DA";
}
.cui-scania-towing_service:before {
  content: "\E0DB";
}
.cui-scania-trailer:before {
  content: "\E0DC";
}
.cui-scania-training_classroom:before {
  content: "\E0DD";
}
.cui-scania-transport_refrigeration_service:before {
  content: "\E0DE";
}
.cui-scania-truck-sale:before {
  content: "\E0DF";
}
.cui-scania-truck:before {
  content: "\E0E0";
}
.cui-scania-truck_assembly:before {
  content: "\E0E1";
}
.cui-scania-truck_connected:before {
  content: "\E0E2";
}
.cui-scania-truck_service:before {
  content: "\E0E3";
}
.cui-scania-twitter:before {
  content: "\E0E4";
}
.cui-scania-tyre_service:before {
  content: "\E0E5";
}
.cui-scania-umbrella:before {
  content: "\E0E6";
}
.cui-scania-uptime:before {
  content: "\E0E7";
}
.cui-scania-used_vehicle_sale:before {
  content: "\E0E8";
}
.cui-scania-vehicle_rent:before {
  content: "\E0E9";
}
.cui-scania-vehicle_washing:before {
  content: "\E0EA";
}
.cui-scania-video_player:before {
  content: "\E0EB";
}
.cui-scania-warning:before {
  content: "\E0EC";
}
.cui-scania-wifi:before {
  content: "\E0ED";
}
.cui-scania-work:before {
  content: "\E0EE";
}
.cui-scania-wrench:before {
  content: "\E0EF";
}
.cui-scania-youtube:before {
  content: "\E0F0";
}
.cui-sdds-scania-arrow_diagonal:before {
  content: "\E0F1";
}
.cui-sdds-scania-arrow_down:before {
  content: "\E0F2";
}
.cui-sdds-scania-arrow_left:before {
  content: "\E0F3";
}
.cui-sdds-scania-arrow_right:before {
  content: "\E0F4";
}
.cui-sdds-scania-arrow_up:before {
  content: "\E0F5";
}
.cui-sdds-scania-back:before {
  content: "\E0F6";
}
.cui-sdds-scania-bento:before {
  content: "\E0F7";
}
.cui-sdds-scania-burger:before {
  content: "\E0F8";
}
.cui-sdds-scania-calendar-inactive:before {
  content: "\E0F9";
}
.cui-sdds-scania-calendar:before {
  content: "\E0FA";
}
.cui-sdds-scania-cart:before {
  content: "\E0FB";
}
.cui-sdds-scania-chevron_down:before {
  content: "\E0FC";
}
.cui-sdds-scania-chevron_left:before {
  content: "\E0FD";
}
.cui-sdds-scania-chevron_right:before {
  content: "\E0FE";
}
.cui-sdds-scania-chevron_up:before {
  content: "\E0FF";
}
.cui-sdds-scania-clock-inactive:before {
  content: "\E100";
}
.cui-sdds-scania-clock:before {
  content: "\E101";
}
.cui-sdds-scania-copy:before {
  content: "\E102";
}
.cui-sdds-scania-cross:before {
  content: "\E103";
}
.cui-sdds-scania-document:before {
  content: "\E104";
}
.cui-sdds-scania-document_check:before {
  content: "\E105";
}
.cui-sdds-scania-document_eye:before {
  content: "\E106";
}
.cui-sdds-scania-document_tool:before {
  content: "\E107";
}
.cui-sdds-scania-document_wrong:before {
  content: "\E108";
}
.cui-sdds-scania-doner:before {
  content: "\E109";
}
.cui-sdds-scania-double_kebab:before {
  content: "\E10A";
}
.cui-sdds-scania-download:before {
  content: "\E10B";
}
.cui-sdds-scania-export:before {
  content: "\E10C";
}
.cui-sdds-scania-eye-inactive:before {
  content: "\E10D";
}
.cui-sdds-scania-eye:before {
  content: "\E10E";
}
.cui-sdds-scania-filters-inactive:before {
  content: "\E10F";
}
.cui-sdds-scania-filters:before {
  content: "\E110";
}
.cui-sdds-scania-fuel_gauge-inactive:before {
  content: "\E111";
}
.cui-sdds-scania-fuel_gauge:before {
  content: "\E112";
}
.cui-sdds-scania-heart-inactive:before {
  content: "\E113";
}
.cui-sdds-scania-heart:before {
  content: "\E114";
}
.cui-sdds-scania-image-inactive:before {
  content: "\E115";
}
.cui-sdds-scania-image:before {
  content: "\E116";
}
.cui-sdds-scania-image_add:before {
  content: "\E117";
}
.cui-sdds-scania-image_set:before {
  content: "\E118";
}
.cui-sdds-scania-info:before {
  content: "\E119";
}
.cui-sdds-scania-kebab:before {
  content: "\E11A";
}
.cui-sdds-scania-link:before {
  content: "\E11B";
}
.cui-sdds-scania-link_broken:before {
  content: "\E11C";
}
.cui-sdds-scania-lock-inactive:before {
  content: "\E11D";
}
.cui-sdds-scania-lock:before {
  content: "\E11E";
}
.cui-sdds-scania-meatballs:before {
  content: "\E11F";
}
.cui-sdds-scania-message-inactive:before {
  content: "\E120";
}
.cui-sdds-scania-message:before {
  content: "\E121";
}
.cui-sdds-scania-mileage-inactive:before {
  content: "\E122";
}
.cui-sdds-scania-mileage:before {
  content: "\E123";
}
.cui-sdds-scania-minus:before {
  content: "\E124";
}
.cui-sdds-scania-notification-inactive:before {
  content: "\E125";
}
.cui-sdds-scania-notification:before {
  content: "\E126";
}
.cui-sdds-scania-pin-inactive:before {
  content: "\E127";
}
.cui-sdds-scania-pin:before {
  content: "\E128";
}
.cui-sdds-scania-plus:before {
  content: "\E129";
}
.cui-sdds-scania-profile-inactive:before {
  content: "\E12A";
}
.cui-sdds-scania-profile:before {
  content: "\E12B";
}
.cui-sdds-scania-proportions-inactive:before {
  content: "\E12C";
}
.cui-sdds-scania-proportions:before {
  content: "\E12D";
}
.cui-sdds-scania-refresh:before {
  content: "\E12E";
}
.cui-sdds-scania-save:before {
  content: "\E12F";
}
.cui-sdds-scania-search:before {
  content: "\E130";
}
.cui-sdds-scania-settings:before {
  content: "\E131";
}
.cui-sdds-scania-sorting:before {
  content: "\E132";
}
.cui-sdds-scania-speedometer-inactive:before {
  content: "\E133";
}
.cui-sdds-scania-speedometer:before {
  content: "\E134";
}
.cui-sdds-scania-star:before {
  content: "\E135";
}
.cui-sdds-scania-support:before {
  content: "\E136";
}
.cui-sdds-scania-tick:before {
  content: "\E137";
}
.cui-sdds-scania-timer:before {
  content: "\E138";
}
.cui-sdds-scania-tool-inactive:before {
  content: "\E139";
}
.cui-sdds-scania-tool:before {
  content: "\E13A";
}
.cui-sdds-scania-trash-inactive:before {
  content: "\E13B";
}
.cui-sdds-scania-trash:before {
  content: "\E13C";
}
.cui-sdds-scania-truck:before {
  content: "\E13D";
}
.cui-sdds-scania-upload:before {
  content: "\E13E";
}
